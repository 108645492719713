<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data'/>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('merchant.title') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->
              <datatable-dropdown />
            </div>
          </div>
          <div v-if="checkPermission('crm.finance.out-purchases.index') && pendingTransactions.length > 0" class="pl-10 py-5">
            <p class="row font-weight-bold">{{ $t('merchant.buy_crypto_from_outside') }}</p>
            <div class="row">
              <div v-for="(pendingTransaction, key) in pendingTransactions" :key="key">  
                <button 
                  type="button" class="btn btn-warning text-dark rounded-0 border-right border-right-dark" 
                  v-if="buyCryptoFromOutsideBtnPermission(pendingTransaction.currency.code)"
                  @click="outsidePurchase(pendingTransaction.currency)"
                >
                  <b>{{ pendingTransaction.currency.code }}</b> {{ pendingTransaction.outPurchase}}
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <Datatable
                ref="datatable"
                :url="datatableUrl"
                :dataUrl="datatableDataUrl"
                @data="loadDatatableData($event)"
            >
                <!-- <template v-slot:head>
                    Table head özelleştirme
                </template> -->
                <template v-slot:body>
                  <tr v-for="(item, index) in datatableData" :key="'data-' + index">
                    <th scope="row">{{ item.id }}</th>
                    <td>{{ item.user.name }}</td>
                    <td>{{ item.institution.name }}</td>
                    <td>{{ item.employee }}</td>
                    <td>{{ item.fullname }}</td>
                    <td>{{ item.crypto_type }}</td>
                    <td>{{ item.base_currency }}</td>
                    <td>{{ (item.type) ? $t(`service.${item.type}`) : item.type }}</td>
                    <td>{{ item.amount }}</td>
                    <td>{{ item.price }}</td>
                    <td>{{ item.sub_total }}</td>
                    <td><span class="badge" :class="statusClass(item.status)">{{ statusLabel(item.status) }}</span></td>
                    <td v-html="item.commission"></td>
                    <td>{{ item.parent_id }}</td>
                    <td v-html="item.completed_at"></td>
                    <td>{{ item.created_at }}</td>
                    <td>
                      <div class="d-flex justify-content-end flex-shrink-0">
                        <button v-if="checkPermission('crm.merchant.transactions.pending.modal')" @click="showModal(item.id)" class="btn btn-icon btn-clean btn-bg-light btn-sm mr-1" :title="$t('commons.details')">
                          <i class="flaticon2-document"></i>
                        </button>
                        <button v-if="checkPermission('crm.merchant.transactions.pending.pdf')" @click.prevent="downloadItem(item.id)" class="btn btn-icon btn-clean btn-bg-light btn-sm" :title="$t('commons.download')">
                          <i class="far fa-file fs-4"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </template>
            </Datatable>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
    <DetailModal />
    <SmsModal @transactionsSmsSentSuccess="initDatatable" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import DetailModal from './DetailModal.vue';
import SmsModal from './SmsModal.vue';
import API_OLD from '@/api/index_old';
import 'bootstrap-daterangepicker';
import Datatable from "@/components/datatable/DatatableMerchant.vue";
import api_old from "@/api/index_old";
import DatatableDropdown from "@/components/datatable/Dropdown.vue";
import $ from "jquery";

export default {
  name: 'TransactionsList',
  data() {
    return {
      isLoading:true,
      datatableUrl: "",
      datatableDataUrl: "",
      datatableData: [],
    }
  },
  components: {
    Breadcrumb,
    DetailModal,
    SmsModal,
    Datatable,
    DatatableDropdown
  },
  methods: {
    ...mapActions('global', ['GET_PDF']),
    downloadItem(id) {
      let data = { url: `${API_OLD.transactions}/${id}/pdf`, label: `${id}-merchant-invoice.pdf` };
      this.GET_PDF(data);
    },
    checkPermission( permission) {
      let status = false;
      this.permissions?.forEach(function (item) {
        if (item.name == permission) {
          status = true;
        }
      });
      return status
    },
    showModal(id) {
      this.$store.dispatch('transactions/GET_DETAIL_MODAL_DATA', id)
    },
    outsidePurchase(currency) {
      localStorage.setItem('outsidePurchase', JSON.stringify({ 
        type: '10', // 10: buy, 20: sell
        typeStr: this.$t('merchant.buy'),
        fromDetailModal: false,
        buySellFromOutside: null,
        amountFormatted: null,
        amountDecimal: null,
        currencyId: currency.id,
        currencyCode: currency.code,
      }))
      this.$router.push({ 
        name: 'outside-purchase'
      });
    },
    buyCryptoFromOutsideBtnPermission(code) {
      return this.permissions?.length > 0 ? this.checkPermission(`crm.finance.out-purcase.coin.${code}`) : false;
    },

    getTimeForParsedDate(rawDate) {
        const dateArray= rawDate.split("/");  // "18/02/2022"(DD/MM/YYYY) - convert to "2022-02-18"(YYYY-MM-DD)
        const parsedDate= `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
        return new Date(parsedDate).getTime();
    },
    statusClass(status) {
      switch (status) {
          case 'pending.pending_operation_approval':
              return 'badge-warning';
          case 'pending.error':
              return 'badge-light';
          case 'pending.completed':
              return 'badge-success';
          case 'pending.canceled':
              return 'badge-danger';
          default:
              return '';
      }
    },
    statusLabel(status) {
      if(status) {
          return this.$t(`service.${status}`) 
      }
      return '-'
    },
    loadDatatableData(data) {
      this.$set(this, "datatableData", data);
    },
    initDatatable() {
      this.$refs.datatable.loadData();
    }
  },
  computed: {
    ...mapState({
      permissions: (state) => state.customer.permissions,
      pendingTransactions: (state) => state.transactions.pendingTransactions,
      lang: (state) => state.global.lang
    }),
    breadcrumb_data() {
      return {
        title: this.$t('merchant.title'),
        subtitle: [
          this.$t('merchant.all_transactions')
        ]
      }
    }
  },
  created() {
    if(this.permissions?.length > 0 && !this.checkPermission('crm.merchant.transactions.pending.data')) {
      this.$router.push('/no-permission')
    }
    
    this.$store.commit('transactions/detailsModal', { id: '', visible: false});
    this.$store.commit("transactions/TOGGLE_SMS_MODAL", false);

    this.$store.dispatch('transactions/GET_PENDING_TRANSACTIONS');
    this.datatableUrl = api_old.transactions_datatable;
    this.datatableDataUrl = api_old.transactions_datatable_data;

    const self = this;
    self.$nextTick(function() {
        $("#printExportItem").hide();
        $("#copyExportItem").hide();
        $("#excelExport").on("click", function (e) {
            e.preventDefault();
            let data = { url: `transactions/pending/export`, label: `branch-all-transactions.xlsx`, reqPayload: self.$refs.datatable.selectedFilters };
            self.$store.dispatch('global/GET_EXCEL', data);
        });
        $("#csvExportItem").hide();
        $("#pdfExportItem").hide();
    })
  },
}
</script>
<style lang="scss">
</style>
